import axios from "axios";

export default {
    state: {
        countries: [],
        states: [],
        cities: []
    },
    mutations: {
        setterCountries(state,data) {
            state.countries = [...data];
        },
        setterStates(state,data) {
            state.states = data;
        },
        setterCities(state,data) {
            state.cities = data;
        },
    },
    getters:{
        getCountries: state =>{
            return state.countries;
        },
        getStates: state =>{
            return state.states;
        },
        getCities: state =>{
            return state.cities;
        },
    },
    actions: {
        async getCountries(context) {
            await axios.get('/api/countries').then((response) => {
                context.commit('setterCountries',response.data);
            });
        },
        async getStates(context, country_id) {
            await axios.get(`/api/states/${country_id}`).then((response) => {
                context.commit('setterStates', response.data);
            });
        },
        getCities(context, state_id) {
            axios.get(`/api/cities/${state_id}`).then((response) => {
                context.commit('setterCities',response.data);
            });
        },
    }
};
