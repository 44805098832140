import { createStore } from 'vuex';
import stores from './modules/stores';
import delivery from './modules/delivery';
import countries from './modules/countries';
import users from './modules/users';
import packages from "./modules/packages";
import payment from "./modules/payment";
import entrust from './modules/entrust';
import mail from './modules/mail';
import review from './modules/review';

const store = createStore({
    modules: {
        stores: stores,
        delivery: delivery,
        countries: countries,
        users: users,
        packages: packages,
        payment: payment,
        entrust: entrust,
        mail: mail,
        review: review
    }
});

export default store;
