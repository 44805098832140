import axios from "axios";

export default {
    state: {
    },
    mutations: {

    },
    getters:{

    },
    actions: {
        async storeReview(context, data) {
            try {
                await axios.post('/reviews/store', data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
    }
};
