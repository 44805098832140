import axios from "axios";

export default {
    state: {
        addresses: []
    },
    mutations: {
        setterAddresses(state, data) {
            state.addresses = data;
        }
    },
    getters:{
        getAddresses(state) {
            return state.addresses;
        }
    },
    actions: {
        async changePassword(context, data) {
            try {
                await axios.post('/user/change-password', data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async updateProfile(context, data) {
            try {
                await axios.post('/user/update-profile', data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async getAddresses(context) {
            try {
                await axios.get('/user/addresses').then((response) => {
                    context.commit('setterAddresses', response.data);
                });
            } catch (e) {
                console.log(e);
                throw e;
            }

        },
        async createOrUpdateAddress(context, data) {
            try {
                await axios.post('/user/create-update-address', data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async deleteAddress(context, id) {
            try {
                await axios.delete(`/user/delete-address/${id}`);
            } catch (e) {
                console.log(e);
                throw e;
            }
        }
    }
};
