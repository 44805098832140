import axios from "axios";

export default {
    state: {
        entrust: {},
        entrustDeclarations: [],
        redirectUrl: null
    },
    mutations: {
        setEntrust(state, entrust) {
            state.entrust = entrust;
        },
        setEntrustDeclarations(state, entrustDeclarations) {
            state.entrustDeclarations = entrustDeclarations;
        },
        setRedirectUrl(state, url) {
            state.redirectUrl = url;
        }
    },
    getters:{
        getEntrust(state) {
            return state.entrust;
        },
        getEntrustDeclarations(state) {
            return state.entrustDeclarations;
        },
        getRedirectUrl(state) {
            return state.redirectUrl;
        }
    },
    actions: {
        async fetchEntrust({ commit }, id) {
            try {
                const response = await axios.get(`/user-dashboard/entrust/show/${id}`);
                commit('setEntrust', response.data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async fetchEntrustDeclarations({ commit }) {
            try {
                const response = await axios.get('/user-dashboard/entrust/create?request=ajax');
                commit('setEntrustDeclarations', response.data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async createOrderProduct({ commit }, data) {
            try {
                await axios.post('/user-dashboard/entrust/store-declaration' ,data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async deleteEntrustDeclaration({ commit }, id) {
            try {
                await axios.delete(`/user-dashboard/entrust/declaration/${id}`);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async createEntrust({ commit }, data) {
            try {
                const response = await axios.post('/user-dashboard/entrust/store', data);
                commit('setRedirectUrl', response.data.redirect_url);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async deleteEntrust({ commit }, id) {
            try {
                await axios.delete(`/user-dashboard/entrust/${id}`);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async rollbackEntrust({ commit }, id) {
            try {
                await axios.get(`/user-dashboard/entrust/rollback/${id}`);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async payEntrust({ commit }, id) {
            try {
                await axios.get(`/user-dashboard/entrust/pay/${id}`);
            } catch (e) {
                console.log(e);
                throw e;
            }
        }
    }
};
