import {objectToQueryString} from "../../../common/helpers";

const state = () => ({
    mailsList: [],
    messagesList: [],
    labelsList: [],
    mailsIsLoading: false,
    messagesIsLoading: false,
});

const getters = {
    mailsList(state) {
        return state.mailsList;
    },
    messagesList(state) {
        return state.messagesList;
    },
    labelsList(state) {
        return state.labelsList;
    },
    mailsIsLoading(state) {
        return state.mailsIsLoading;
    },
    messagesIsLoading(state) {
        return state.messagesIsLoading;
    },
};

const mutations = {
    setterMailsList(state, data) {
        state.mailsList = data;
    },
    setterMessagesList(state, data) {
        state.messagesList = data;
    },
    setterLabelsList(state, data) {
        state.labelsList = data;
    },
    setterMailsIsLoading(state, data) {
        state.mailsIsLoading = data;
    },
    setterMessagesIsLoading(state, data) {
        state.messagesIsLoading = data;
    },
};

const actions = {
    async getMailsList(context, payload) {
        context.commit('setterMailsIsLoading', true);
        context.commit('setterMailsList', []);
        //build query string
        const queryString = objectToQueryString(payload);
        await axios.get(`/user-dashboard/mails${queryString}`).then((response) => {
            context.commit('setterMailsList', response.data);
        }).finally(() => {
            context.commit('setterMailsIsLoading', false);
        });
    },
    async getMessagesList(context, payload) {
        context.commit('setterMessagesIsLoading', true);
        context.commit('setterMessagesList', []);
        //build query string
        const queryString = objectToQueryString(payload);
        await axios.get(`/user-dashboard/mails/${payload.mail_id}/messages${queryString}`).then((response) => {
            context.commit('setterMessagesList', response.data);
        }).finally(() => {
            context.commit('setterMessagesIsLoading', false);
        });
    },
    async getLabelsList(context, payload) {
        //build query string
        const queryString = objectToQueryString(payload);
        await axios.get(`/user-dashboard/mails/labels${queryString}`).then((response) => {
            context.commit('setterLabelsList', response.data);
        });
    },
    async bulkAction(context, payload) {
        await axios.post(`/user-dashboard/mails/bulk/${payload.action}`, payload.data);
    },
    async storeMessage(context, payload) {
        await axios.post(`/user-dashboard/mails/${payload.mail_id}/messages/store`, payload.data);
    },
    async storeMail(context, payload) {
        await axios.post(`/user-dashboard/mails/store`, payload);
    },
    async setMailRead(context, payload) {
        await axios.post(`/user-dashboard/mails/${payload.id}/set-read`, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

