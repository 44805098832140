import axios from "axios";

export default {
    state: {
        deliveryCompanies: [],
        calculateResults: [],
    },
    mutations: {
        setterDeliveryCompanies(state, data) {
            state.deliveryCompanies = data;
        },
        setterCalculateResults(state,data) {
            state.calculateResults = data;
        },
    },
    getters:{
        getDeliveryCompanies: state =>{
            return state.deliveryCompanies;
        },
        getCalculateResults: state =>{
            return state.calculateResults;
        },
    },
    actions: {
        async getDeliveryCompaniesForParcel(context, payload) {
            const url = `?parcel_id=${payload.parcelId}&address_id=${payload.addressId}`;
            const response = await axios.get(`/user-dashboard/packages/parcel-delivery-companies${url}`);
            context.commit('setterDeliveryCompanies', response.data);
        },
        async getCalculateResults(context, data) {
            await axios.post('/calculate-rate' ,data).then((response) => {
                context.commit('setterCalculateResults',response.data);
            });
        },
    }
};
