import axios from "axios";

export default {
    state: {
        stores: [],
    },
    mutations: {
        setterStores(state,data) {
            state.stores = data;
        },
    },
    getters:{
        getStores: state =>{
            return state.stores;
        },
    },
    actions: {
        getStores(context, data) {
            axios.post('/popular-stores' ,data).then((response) => {
                context.commit('setterStores',response.data);
            });
        },
    }
};
